import React from "react";
import BottomNav from "./BottomNav";
import Step1pic from "../images/phone.jpg";
import Step2pic from "../images/pooldesign.jpg";
import Step3pic from "../images/thinking.jpg";
import Step4pic from "../images/terms.jpg";
import Step5pic from "../images/supplyies.jpg";
import banner from "../images/poolbanner.gif";

function SidebySide() {
  return (
    <body class="d-flex flex-column">
      <div className="poolbanner">
        <img
          className="img-fluid rounded mb-5 mb-lg-0"
          src={banner}
          alt="..."
        />
      </div>
      <section
        className="py-5 bg-light"
        id="scroll-target"
        style={{ background: "#33B5FF" }}
      >
        <div className="container px-5 my-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <img
                className="img-fluid rounded mb-5 mb-lg-0"
                src={Step1pic}
                alt="..."
              />
            </div>
            <div className="col-lg-6">
              <h2 className="fw-bolder">SPEAK WITH A CONSULTANT</h2>
              <p className="lead fw-normal text-muted mb-0">
                Contact one of our pool specialists for a 30 minute consultation
                free of charge. During your consultation all aspects of the pool
                building process will be shared with you in greater detail and
                will outline each phase and the specified roles of your It's
                Your Pool consultant, design team, your role as well as the role
                of the City or municipality and subcontractors.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6 order-first order-lg-last">
              <img
                className="img-fluid rounded mb-5 mb-lg-0"
                src={Step2pic}
                alt="..."
              />
            </div>
            <div className="col-lg-6">
              <h2 className="fw-bolder">REVIEW</h2>
              <p className="lead fw-normal text-muted mb-0">
                Building a pool is a big decision and all aspects should be
                reviewed carefully. We recommend that you ensure that all of
                your questions have been answered and if not, write them down.
                Ensure that you have a realistic budget for your desired pool as
                well. Finally, ensure you have a start date in mind that will
                work for you.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 bg-light" id="scroll-target">
        <div className="container px-5 my-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <img
                className="img-fluid rounded mb-5 mb-lg-0"
                src={Step3pic}
                alt="..."
              />
            </div>
            <div className="col-lg-6">
              <h2 className="fw-bolder">PURCHASE YOUR POOL PACKAGE</h2>
              <p className="lead fw-normal text-muted mb-0">
                Once you have arrived at an informed decision to work with It's
                Your Pool, it is time to ask any final questions regarding the
                pool package. Once this is complete, you will purchase the
                package.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6 order-first order-lg-last">
              <img
                className="img-fluid rounded mb-5 mb-lg-0"
                src={Step4pic}
                alt="..."
              />
            </div>
            <div className="col-lg-6">
              <h2 className="fw-bolder">DESIGN YOUR POOL</h2>
              <p className="lead fw-normal text-muted mb-0">
                It is now time to design your pool. It's Your Pool will go
                through all design components, size, materials and feature
                components of your desired pool. Once all components are
                outlined, It's Your Pool will create a rendering of your pool
                for you to review and will provide up to 2 revisions at no
                additional charge.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 bg-light" id="scroll-target">
        <div className="container px-5 my-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <img
                className="img-fluid rounded mb-5 mb-lg-0"
                src={Step5pic}
                alt="..."
              />
            </div>
            <div className="col-lg-6">
              <h2 className="fw-bolder">
                CONTRACT YOUR POOL &amp; ORDER SUPPLIES
              </h2>
              <p className="lead fw-normal text-muted mb-0">
                It's Your Pool will provide cost estimates for each phase of
                your pool for your review. In addition, It's Your Pool will aid
                in the process of securing all materials for this phase.
              </p>
            </div>
          </div>
        </div>
      </section>
      <BottomNav />
    </body>
  );
}
export default SidebySide;
