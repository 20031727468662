import React from "react";
import CardComponent from "../Components/CardComponent";

function Purchase() {
  return (
    <div className="PackageSelectPage text-center">
      <h1>YOUR ALL</h1>
      <h1>INCLUSIVE PACKAGE</h1>
      <h1>FOR YOUR POOL</h1>
      <CardComponent />
    </div>
  );
}

export default Purchase;
