import "../App.css";
import React from "react";
import { Link } from "react-router-dom";

import poolrebar from "../ImagesCropped/rebar.jpg";
import freeform1 from "../images/freeform1.jpg";
import geo9 from "../images/geo9.jpg";
import backyard1 from "../images/backyard6.jpg";
import water1 from "../images/water1.jpg";

function PoolPictures() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  return (
    <div className="container-fluid text-center px-4">
      <div style={{ paddingTop: "1%", paddingBottom: "1%" }}>
        <h1>Click An Image To Explore Possibilities</h1>
        <h1>For Your Pool</h1>
      </div>
      <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-4">
        <div className="col thumbnail">
          <Link to="/FreeForm">
            <img
              src={freeform1}
              alt="Nature"
              onClick={scrollToTop}
              className="img-fluid py-3"
            />
            <div className="overlay"></div>
            <div className="caption text-center h3">Free Form</div>
          </Link>
        </div>
        <div className="col thumbnail">
          <Link to="/Geometric">
            <img
              src={geo9}
              alt="Mountains"
              onClick={scrollToTop}
              className="img-fluid py-3"
            />
            <div className="overlay"></div>
            <div className="caption text-center h3">Geo-metric</div>
          </Link>
        </div>
        <div className="col thumbnail">
          <Link to="/Backyard">
            <img
              src={backyard1}
              alt="Snow"
              onClick={scrollToTop}
              className="img-fluid py-3"
            />
            <div className="overlay"></div>
            <div className="caption text-center h3">Backyard</div>
          </Link>
        </div>
        <div className="col thumbnail">
          <Link to="/WaterFeatures">
            <img
              src={water1}
              alt="Lights"
              onClick={scrollToTop}
              className="img-fluid py-3"
            />
            <div className="overlay"></div>
            <div className="caption text-center h3">Water Features</div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PoolPictures;
