import React from "react";
import Steps from "../Components/Steps";

function StepsToBuild() {
  return (
    <div>
      <Steps />
    </div>
  );
}

export default StepsToBuild;
