import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

function BackButton() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  return (
    <div style={{ marginTop: "10px", marginBottom: "20px" }}>
      <Link to="/PoolPictures">
        <Button variant="primary" size="lg" onClick={scrollToTop}>
          Back
        </Button>
      </Link>
    </div>
  );
}

export default BackButton;
