import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import GetStarted from "./Pages/GetStarted";
import StepsToBuild from "./Pages/StepsToBuild";
import PoolPictures from "./Pages/PoolPictures";
import Contact from "./Pages/Contact";
import FreeForm from "./Pages/FreeForm";
import Geometric from "./Pages/Geometric";
import Backyard from "./Pages/Backyard";
import WaterFeatures from "./Pages/WaterFeatures";
import Purchase from "./Pages/Purchase";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="GetStarted" element={<GetStarted />} />
          <Route path="StepsToBuild" element={<StepsToBuild />} />
          <Route path="PoolPictures" element={<PoolPictures />} />
          <Route path="Contact" element={<Contact />} />
          <Route path="FreeForm" element={<FreeForm />} />
          <Route path="Geometric" element={<Geometric />} />
          <Route path="Backyard" element={<Backyard />} />
          <Route path="WaterFeatures" element={<WaterFeatures />} />
          <Route path="Purchase" element={<Purchase />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
