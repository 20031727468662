import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Container, Nav } from "react-bootstrap";
import Logo from "../images/LOGO_5.png";
import Logo2 from "../images/rodeo.png";

function BottomNav() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "sharp", // for smoothly scrolling
    });
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-secondary">
        <div className="container px-5">
          <a className="navbar-brand" href="index.html"></a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Menu
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0 h5">
              <li className="nav-item">
                <Link
                  className="nav-link text-white px-3"
                  to="/Home"
                  onClick={() => {
                    scrollToTop();
                    setTimeout(() => {
                      window.location.reload(false);
                    }, 0);
                  }}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-white px-3"
                  to="/GetStarted"
                  onClick={() => {
                    scrollToTop();
                    setTimeout(() => {
                      window.location.reload(false);
                    }, 0);
                  }}
                >
                  Get Started
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-white px-3"
                  to="/StepsToBuild"
                  onClick={() => {
                    scrollToTop();
                    setTimeout(() => {
                      window.location.reload(false);
                    }, 0);
                  }}
                >
                  Steps To Build Your Pool
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-white px-3"
                  to="/PoolPictures"
                  onClick={() => {
                    scrollToTop();
                    setTimeout(() => {
                      window.location.reload(false);
                    }, 0);
                  }}
                >
                  Pool Styles & Accessories
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-white px-3"
                  to="/Contact"
                  onClick={() => {
                    scrollToTop();
                    setTimeout(() => {
                      window.location.reload(false);
                    }, 0);
                  }}
                >
                  Contact
                </Link>
              </li>
            </ul>
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0 h5">
              <li className="nav-item">
                <Link
                  className="nav-link d-block d-md-block d-lg-none text-white"
                  to="/Purchase"
                  onClick={() => {
                    scrollToTop();
                    setTimeout(() => {
                      window.location.reload(false);
                    }, 0);
                  }}
                >
                  Purchase
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default BottomNav;
