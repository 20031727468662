import React from "react";
import { Link } from "react-router-dom";

function QuestionComponent() {
  return (
    <div className="container-fluid shadow py-5">
      <div className="container">
        <div className="row row-cols-1 row-cols-md-2">
          <div className="col text-center my-auto">
            <h3 className="fw-bolder">HAVE ANY QUESTIONS ABOUT YOUR POOL?</h3>
          </div>
          <div className="col text-center">
            <Link
              to="/Contact"
              className="btn btn-primary rounded-pill w-75"
              style={{ height: "100px" }}
            >
              <h3 className="my-4">Click Here</h3>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuestionComponent;
