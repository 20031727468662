import React from "react";
import { Link } from "react-router-dom";
import { Card, ListGroupItem, ListGroup, Button } from "react-bootstrap";
import logo from "../images/water2.jpg";

function CardComponentHalf() {
  return (
    <Card className="FullCard">
      <Card.Img variant="top" src={logo} />

      <ListGroup className="list-group-flush">
        <ListGroupItem style={{ fontSize: "18px" }}>
          We help you build your pool faster.
        </ListGroupItem>
        <ListGroupItem style={{ fontSize: "18px" }}>
          We provide lower labor costs than a pool company which helps keep
          money in your pocket.
        </ListGroupItem>
        <ListGroupItem style={{ fontSize: "18px" }}>
          We will be able to set your elevation.
        </ListGroupItem>
        <ListGroupItem style={{ fontSize: "18px" }}>
          We help you create your 2D and 3D drawings for a clear vision of what
          your pool will look like.
        </ListGroupItem>
        <ListGroupItem style={{ fontSize: "18px" }}>
          We will help you with your construction plans, property site plans,
          provide a construction checklist and submit them to your city's
          permitting department.
        </ListGroupItem>
        <ListGroupItem style={{ fontSize: "18px" }}>
          We know finding the contractors for your project can be challenging.
          We will help you find the qualified contractors for your project.
        </ListGroupItem>
        <ListGroupItem style={{ fontSize: "18px" }}>
          Finally, what sets us apart is that It's Your Pool provides phone,
          email and in person support throughout your pool building experience.
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
}

export default CardComponentHalf;
