import React from "react";
import Logo from "../images/LOGO_5.png";

function Footer() {
  return (
    <div className="">
      <div className="container-fluid border-top"></div>
      <div className="container">
        <div className="row row-cols-1 row-cols-md-2">
          <div className="col text-center h3">
            <img
              src={Logo}
              alt=""
              className="img-fluid"
              style={{ height: "200px" }}
            />
            It's Your Pool
          </div>
          <div className="col h6 my-auto text-center">
            <h4 className="mb-3">Call Today: (623)-274-2800</h4>

            <div className="row row-cols-1 row-cols-xl-3 mt-0">
              <div className="col">info@itsyourpool.com</div>
              <br />
              <br />
              <div className="col">james@itsyourpool.com</div>
              <br />
              <br />
              <div className="col">johanna@itsyourpool.com</div>
              <br />
              <br />
            </div>
            <small className="ml-2">
              &copy; It's Your Pool, 2022. All rights reserved.
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
