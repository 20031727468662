import React from "react";
import emailjs from "emailjs-com";

function sendEmail(e) {
  e.preventDefault();
  window.alert("Message Sent\nThank You for Questions ");
  window.location.reload(false);

  emailjs
    .sendForm("pools", "template_ofecpzm", e.target, "ngpZsUrSWM86ysQTB")
    .then((res) => {
      console.log(res);
    })
    .catch((err) => console.log(err));
}

function ContactUs() {
  return (
    <container>
      <div className="books container">
        <h1
          className="fw-light text-center text-warning pt-5"
          style={{ fontSize: "400%", marginTop: "-15%", marginBottom: "10%" }}
        >
          Contact Us
        </h1>
        <div className="text-center">
          <form className=" dog mx-auto vstack" onSubmit={sendEmail}>
            <label
              placeholder="Enter Full Name"
              className="required"
              style={{ marginBottom: "5px", color: "white" }}
            >
              Full Name
            </label>
            <input
              placeholder="Enter Full Name"
              type="text"
              name="name"
              required
              className="from-control"
            />

            <label
              className="required"
              style={{ marginBottom: "5px", color: "white" }}
            >
              Email
            </label>
            <input
              placeholder="Enter Email"
              type="email"
              name="user_email"
              required
              className="from-control"
            />

            <label
              className="required"
              style={{ marginBottom: "5px", color: "white" }}
            >
              Phone Number
            </label>
            <input
              placeholder="Enter Phone Number"
              type="number"
              name="number"
              required
              className="from-control"
            />

            <label
              className="required"
              style={{ marginBottom: "5px", color: "white" }}
            >
              Perfect Time To Call
            </label>
            <input
              type="time"
              name="time_to_call"
              min="8:00"
              max="20:00"
              required
              className="from-control"
            />

            <label style={{ marginBottom: "5px", color: "white" }}>
              Message
            </label>
            <textarea
              placeholder="Leave any questions.... "
              name="message"
              rows="4"
              className="from-control"
              style={{ outline: "1px solid black", borderRadius: "2px" }}
            />

            <button
              type="submit"
              className="from-control btn btn btn-primary"
              style={{ marginTop: "30px" }}
            >
              Send
            </button>
          </form>
        </div>
      </div>
    </container>
  );
}

export default ContactUs;
