import React from "react";

import BottomNav from "./BottomNav";

import Step1pic from "../images/design.jpg";
import Step2pic from "../images/new.jpg";
import Step3pic from "../images/pooldesign.jpg";
import Step4pic from "../images/excavating.jpg";
import Step5pic from "../images/plumbing.jpg";
import Step6pic from "../images/rebar.jpg";
import Step7pic from "../images/wiring.jpg";
import Step8pic from "../images/shotcrete.jpg";
import Step9pic from "../images/coping.jpg";
import Step10pic from "../images/landscape.jpg";
import Step11pic from "../images/familyswim.jpg";

function Steps() {
  return (
    <body class="d-flex flex-column">
      <div className="container-fluid" style={{ backgroundColor: "#7CCFFF" }}>
        <h1
          className="align-center"
          style={{
            paddingTop: "5%",
            paddingBottom: "5%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "300%",
          }}
        >
          Build Your Perfect Pool
        </h1>
        <section
          className="py-5 bg-light"
          id="scroll-target"
          style={{ background: "#33B5FF" }}
        >
          <h2
            className="align-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "300%",
            }}
          >
            Step 1
          </h2>
          <div className="container px-5 my-5">
            <div className="row gx-5 align-items-center">
              <div className="col-lg-6">
                <img
                  className="img-fluid rounded mb-5 mb-lg-0"
                  src={Step1pic}
                  alt="..."
                />
              </div>
              <div className="col-lg-6">
                <h2 className="fw-bolder">Designing</h2>
                <p className="lead fw-normal text-muted mb-0">
                  Have and idea already? Let It's Your Pool put your design plan
                  together in a 1/8" scale. Have no clue how you want your pool
                  to look? Obtain ideas from online photos, magazines, books,
                  etc. 1. Decide on how you'd like your pool to look. 2. Have
                  It's Your Pool prepare your pool rendering. 3. Ensure that the
                  pool fits into your budget. 4. Obtain permits and equipment
                  (before plumbing is installed).
                </p>
              </div>
            </div>
          </div>
        </section>
        <h2
          className="align-center"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "300%",
          }}
        >
          Step 2
        </h2>
        <section className="py-5">
          <div className="container px-5 my-5">
            <div className="row gx-5 align-items-center">
              <div className="col-lg-6 order-first order-lg-last">
                <img
                  className="img-fluid rounded mb-5 mb-lg-0"
                  src={Step2pic}
                  alt="..."
                />
              </div>
              <div className="col-lg-6">
                <h2 className="fw-bolder">Optional Water Features</h2>
                <p className="lead fw-normal text-muted mb-0">
                  Water slides, fountains, waterfalls, swim up bars are all
                  options that can be added to your pool giving you a one of a
                  kind pool experience.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5 bg-light" id="scroll-target">
          <h2
            className="align-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "300%",
            }}
          >
            Step 3
          </h2>
          <div className="container px-5 my-5">
            <div className="row gx-5 align-items-center">
              <div className="col-lg-6">
                <img
                  className="img-fluid rounded mb-5 mb-lg-0"
                  src={Step3pic}
                  alt="..."
                />
              </div>
              <div className="col-lg-6">
                <h2 className="fw-bolder">Laying Out Your Pool</h2>
                <p className="lead fw-normal text-muted mb-0">
                  Now it's time to make your pool a reality! Take your pool plan
                  and have the layout painted on the ground. Take a few days to
                  review the lines and measurements to ensure that it's accurate
                  and that no changes need to be made.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5">
          <h2
            className="align-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "300%",
            }}
          >
            Step 4
          </h2>
          <div className="container px-5 my-5">
            <div className="row gx-5 align-items-center">
              <div className="col-lg-6 order-first order-lg-last">
                <img
                  className="img-fluid rounded mb-5 mb-lg-0"
                  src={Step4pic}
                  alt="..."
                />
              </div>
              <div className="col-lg-6">
                <h2 className="fw-bolder">Excavating</h2>
                <p className="lead fw-normal text-muted mb-0">
                  Let's start digging! This process may take a couple of days
                  depending on the pool design, depending on blue stake, and
                  intricacies. At this phase, you will be able to truly see your
                  masterpiece coming to life.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5 bg-light" id="scroll-target">
          <h2
            className="align-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "300%",
            }}
          >
            Step 5
          </h2>
          <div className="container px-5 my-5">
            <div className="row gx-5 align-items-center">
              <div className="col-lg-6">
                <img
                  className="img-fluid rounded mb-5 mb-lg-0"
                  src={Step5pic}
                  alt="..."
                />
              </div>
              <div className="col-lg-6">
                <h2 className="fw-bolder">Plumbing</h2>
                <p className="lead fw-normal text-muted mb-0">
                  Spa jets, drains, chlorination, cleaning, pool filtration,
                  heating and returns are installed at this phase. Don't forget
                  to have all materials on hand prior to this phase for an easy
                  installation process.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5">
          <h2
            className="align-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "300%",
            }}
          >
            Step 6
          </h2>
          <div className="container px-5 my-5">
            <div className="row gx-5 align-items-center">
              <div className="col-lg-6 order-first order-lg-last">
                <img
                  className="img-fluid rounded mb-5 mb-lg-0"
                  src={Step6pic}
                  alt="..."
                />
              </div>
              <div className="col-lg-6">
                <h2 className="fw-bolder">Rebar</h2>
                <p className="lead fw-normal text-muted mb-0">
                  Your pool will need a structure for your shotcrete to adhere
                  to. Installing steel or rebar will ensure the integrity of the
                  shotcrete holding form
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5 bg-light" id="scroll-target">
          <h2
            className="align-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "300%",
            }}
          >
            Step 7
          </h2>
          <div className="container px-5 my-5">
            <div className="row gx-5 align-items-center">
              <div className="col-lg-6">
                <img
                  className="img-fluid rounded mb-5 mb-lg-0"
                  src={Step7pic}
                  alt="..."
                />
              </div>
              <div className="col-lg-6">
                <h2 className="fw-bolder">Utilities</h2>
                <p className="lead fw-normal text-muted mb-0">
                  Lighting is an important factor for your pool not just for
                  aesthetics but for safety as well. At this stage you will want
                  to install all electrical components for your desired lighting
                  package. In addition, let's not forget the plumbing! You will
                  need a plumbing and circulation system installed at this
                  stage.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5">
          <h2
            className="align-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "300%",
            }}
          >
            Step 8
          </h2>
          <div className="container px-5 my-5">
            <div className="row gx-5 align-items-center">
              <div className="col-lg-6 order-first order-lg-last">
                <img
                  className="img-fluid rounded mb-5 mb-lg-0"
                  src={Step8pic}
                  alt="..."
                />
              </div>
              <div className="col-lg-6">
                <h2 className="fw-bolder">Shotcrete</h2>
                <p className="lead fw-normal text-muted mb-0">
                  During this phase, 8 inch thick shotcrete is applied to your
                  frame. Shotcrete is pumped through a hose and onto your pool.
                  It is concrete that is rapidly shot to your pool.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5 bg-light" id="scroll-target">
          <h2
            className="align-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "300%",
            }}
          >
            Step 9
          </h2>
          <div className="container px-5 my-5">
            <div className="row gx-5 align-items-center">
              <div className="col-lg-6">
                <img
                  className="img-fluid rounded mb-5 mb-lg-0"
                  src={Step9pic}
                  alt="..."
                />
              </div>
              <div className="col-lg-6">
                <h2 className="fw-bolder">Coping and Tiling</h2>
                <p className="lead fw-normal text-muted mb-0">
                  Installing surrounding tile and trim coping not only adds your
                  unique design but it also minimizes maintenance. Treated water
                  may discolor your pool 3 inches above and 3 inches below
                  (typically) your water line. Adding tile will take this hassle
                  to rest. In addition, at this stage you will install your
                  desired pool interior finish such as pebble tech, plaster,
                  tile or stone for a unique interior finish.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5">
          <h2
            className="align-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "300%",
            }}
          >
            Step 10
          </h2>
          <div className="container px-5 my-5">
            <div className="row gx-5 align-items-center">
              <div className="col-lg-6 order-first order-lg-last">
                <img
                  className="img-fluid rounded mb-5 mb-lg-0"
                  src={Step10pic}
                  alt="..."
                />
              </div>
              <div className="col-lg-6">
                <h2 className="fw-bolder">Optional Features</h2>
                <p className="lead fw-normal text-muted mb-0">
                  How about adding some additional flair to your pool
                  surroundings such as an outdoor ramada, unique pool
                  decking,outdoor bar-b-q/bar/kitchen area or firepit? Adding
                  such features can quickly turn your pool area into a complete
                  oasis that you will surely enjoy.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section
          className="py-5 bg-light"
          id="scroll-target"
          style={{ background: "#33B5FF" }}
        >
          <h2
            className="align-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "300%",
            }}
          >
            Step 11
          </h2>
          <div className="container px-5 my-5">
            <div className="row gx-5 align-items-center">
              <div className="col-lg-6">
                <img
                  className="img-fluid rounded mb-5 mb-lg-0"
                  src={Step11pic}
                  alt="..."
                />
              </div>
              <div className="col-lg-6">
                <h2 className="fw-bolder">Fill It and Swim</h2>
                <p className="lead fw-normal text-muted mb-0">
                  Finally!!!!! Fill up your pool and swim to your hearts
                  content! You deserve it!
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <BottomNav />
    </body>
  );
}

export default Steps;
