import React from "react";
import sideImage from "../images/poolhouse.jpg";

function WhoWeAre() {
  return (
    <section className="py-5" id="features">
      <div className="container-fluid mt-0">
        <div className="row gx-5 d-block d-sm-block d-md-none">
          <div className="col-lg-7">
            <div className="row gx-5">
              <div className="col mb-5 h-100">
                <h1
                  className="text-center"
                  style={{ fontFamily: "Arial, sans-serif" }}
                >
                  <strong>Who We Are</strong>
                </h1>
                <div style={{ fontSize: "20px" }}>
                  <p>
                    It's Your Pool partners with their clients to design the
                    pool of their dreams. Whether you desire waterfalls,
                    negative edges, a spa or decorative interior lighting, we
                    can design it for you and connect you with expert
                    sub-contractors that will provide detailed estimates and
                    outline construction processes.
                  </p>
                  <p>
                    With our proven outline, process, and working with your
                    cities building codes, It's Your Pool makes it easy to
                    obtain timely estimates while saving you money.
                  </p>
                  <p>
                    Our clients do not have to spend time doing the heavy
                    lifting and research with multiple pool contractors. With
                    It's Your Pool you can design your pool today! Just choose
                    the custom features and receive a no hassle package price.
                    Upon completion of your design you will find outstanding
                    cost savings. Our design team works with city permit
                    departments, design engineers and sub-contractors which we
                    put you in contact with directly. This keeps the process
                    smooth, transparent and eliminates mark up costs which are
                    normally added in an estimate from a pool company which
                    could be thousands of dollars. It's Your Pool works to keep
                    the mark up costs in YOUR pocket.
                  </p>
                </div>
              </div>
              {/*<div className="col mb-5 h-100">
                <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                  <i className="bi bi-collection"></i>
                </div>
                <h2 className="h5">Featured title</h2>
                <p className="mb-0">
                  Paragraph of text beneath the heading to explain the heading.
                  Here is just a bit more text.
                </p>
              </div>
              <div className="col mb-5 h-100">
                <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                  <i className="bi bi-building"></i>
                </div>
                <h2 className="h5">Featured title</h2>
                <p className="mb-0">
                  Paragraph of text beneath the heading to explain the heading.
                  Here is just a bit more text.
                </p>
              </div>
              <div className="col mb-5 mb-md-0 h-100">
                <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                  <i className="bi bi-toggles2"></i>
                </div>
                <h2 className="h5">Featured title</h2>
                <p className="mb-0">
                  Paragraph of text beneath the heading to explain the heading.
                  Here is just a bit more text.
                </p>
              </div>
              <div className="col h-100">
                <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                  <i className="bi bi-toggles2"></i>
                </div>
                <h2 className="h5">Featured title</h2>
                <p className="mb-0">
                  Paragraph of text beneath the heading to explain the heading.
                  Here is just a bit more text.
                </p>
             </div>*/}
            </div>
          </div>
          <div className="col-lg-5 mb-0 mb-lg-0">
            <img src={sideImage} alt="poolhouse" className="img-fluid" />
          </div>
        </div>
        <div className="row gx-5 mt-5">
          <div className="col-lg-5 mb-0 mb-lg-0 d-sm-none d-md-block d-none d-sm-block">
            <img src={sideImage} alt="poolhouse" className="img-fluid" />
          </div>
          <div className="col-lg-7 d-sm-none d-md-block d-none d-sm-block">
            <div className="row gx-5">
              <div className="col mb-5 h-100">
                <h1
                  className="text-center"
                  style={{ fontFamily: "Arial, sans-serif" }}
                >
                  <strong>Who We Are</strong>
                </h1>
                <div style={{ fontSize: "20px" }}>
                  <p>
                    It's Your Pool partners with their clients to design the
                    pool of their dreams. Whether you desire waterfalls,
                    negative edges, a spa or decorative interior lighting, we
                    can design it for you and connect you with expert
                    sub-contractors that will provide detailed estimates and
                    outline construction processes.
                  </p>
                  <p>
                    With our proven outline, process, and working with your
                    cities building codes, It's Your Pool makes it easy to
                    obtain timely estimates while saving you money.
                  </p>
                  <p>
                    Our clients do not have to spend time doing the heavy
                    lifting and research with multiple pool contractors. With
                    It's Your Pool you can design your pool today! Just choose
                    the custom features and receive a no hassle package price.
                    Upon completion of your design you will find outstanding
                    cost savings. Our design team works with city permit
                    departments, design engineers and sub-contractors which we
                    put you in contact with directly. This keeps the process
                    smooth, transparent and eliminates mark up costs which are
                    normally added in an estimate from a pool company which
                    could be thousands of dollars. It's Your Pool works to keep
                    the mark up costs in YOUR pocket.
                  </p>
                </div>
              </div>
              {/*<div className="col mb-5 h-100">
                <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                  <i className="bi bi-collection"></i>
                </div>
                <h2 className="h5">Featured title</h2>
                <p className="mb-0">
                  Paragraph of text beneath the heading to explain the heading.
                  Here is just a bit more text.
                </p>
              </div>
              <div className="col mb-5 h-100">
                <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                  <i className="bi bi-building"></i>
                </div>
                <h2 className="h5">Featured title</h2>
                <p className="mb-0">
                  Paragraph of text beneath the heading to explain the heading.
                  Here is just a bit more text.
                </p>
              </div>
              <div className="col mb-5 mb-md-0 h-100">
                <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                  <i className="bi bi-toggles2"></i>
                </div>
                <h2 className="h5">Featured title</h2>
                <p className="mb-0">
                  Paragraph of text beneath the heading to explain the heading.
                  Here is just a bit more text.
                </p>
              </div>
              <div className="col h-100">
                <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                  <i className="bi bi-toggles2"></i>
                </div>
                <h2 className="h5">Featured title</h2>
                <p className="mb-0">
                  Paragraph of text beneath the heading to explain the heading.
                  Here is just a bit more text.
                </p>
             </div>*/}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhoWeAre;
