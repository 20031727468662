import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Container, Nav } from "react-bootstrap";
import Logo from "../images/LOGO_5.png";
import Logo2 from "../images/rodeo.png";

function NavbarComponent() {
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <Link
              className="navbar-brand"
              to="/Home"
              onClick={() => {
                setTimeout(() => {
                  window.location.reload(false);
                }, 0);
              }}
            >
              <img
                src={Logo2}
                alt="Its Your Pool"
                style={{ height: "100px" }}
                className="h-100"
              />
            </Link>
          </div>
          <div className="col my-auto text-center">
            <h3>Call Today: (623)-274-2800</h3>
          </div>
          <div className="col text-center my-auto d-none d-md-block">
            <Link
              to="/Purchase"
              className="btn btn-primary rounded w-50"
              style={{ height: "50px" }}
            >
              <h3 className="my-auto">Purchase</h3>
            </Link>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
        <div className="container px-5">
          <a className="navbar-brand" href="index.html"></a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Menu
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0 h5">
              <li className="nav-item">
                <Link
                  className="nav-link text-white px-3"
                  to="/Home"
                  onClick={() => {
                    setTimeout(() => {
                      window.location.reload(false);
                    }, 0);
                  }}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-white px-3"
                  to="/GetStarted"
                  onClick={() => {
                    setTimeout(() => {
                      window.location.reload(false);
                    }, 0);
                  }}
                >
                  Get Started
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-white px-3"
                  to="/StepsToBuild"
                  onClick={() => {
                    setTimeout(() => {
                      window.location.reload(false);
                    }, 0);
                  }}
                >
                  Steps To Build Your Pool
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-white px-3"
                  to="/PoolPictures"
                  onClick={() => {
                    setTimeout(() => {
                      window.location.reload(false);
                    }, 0);
                  }}
                >
                  Pool Styles & Accessories
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-white px-3"
                  to="/Contact"
                  onClick={() => {
                    setTimeout(() => {
                      window.location.reload(false);
                    }, 0);
                  }}
                >
                  Contact
                </Link>
              </li>
            </ul>
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0 h5">
              <li className="nav-item">
                <Link
                  className="nav-link d-block d-md-block d-lg-none text-white"
                  to="/Purchase"
                  onClick={() => {
                    setTimeout(() => {
                      window.location.reload(false);
                    }, 0);
                  }}
                >
                  Purchase
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavbarComponent;
