import React from "react";
import BackButton from "../Components/BackButton";
import ModalImage from "react-modal-image";
import geo1 from "../images/geo1.jpg";
import geo2 from "../images/geo2.jpg";
import geo3 from "../images/geo3.jpg";
import geo4 from "../images/geo4.jpg";
import geo5 from "../images/geo5.jpg";
import geo6 from "../images/geo6.jpg";
import geo7 from "../images/geo7.jpg";
import geo8 from "../images/geo8.jpg";
import geo9 from "../images/geo9.jpg";
import geo10 from "../images/geo10.jpg";
import geo11 from "../images/geo11.jpg";
import { Modal } from "react-bootstrap";

function Geometric() {
  return (
    <div className="container-fluid text-center" style={{ paddingTop: "1%" }}>
      <div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-4 pb-4">
        <div class="col pb-4">
          <ModalImage small={geo10} large={geo10} alt="Pool" />
        </div>
        <div class="col pb-4">
          <ModalImage small={geo9} large={geo9} alt="Pool" />
        </div>
        <div class="col pb-4">
          <ModalImage small={geo11} large={geo11} alt="Pool" />
        </div>
        <div class="col pb-4">
          <ModalImage small={geo8} large={geo8} alt="Pool" />
        </div>
      </div>
      <div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-4 pb-4">
        <div class="col pb-4">
          <ModalImage small={geo5} large={geo5} alt="Pool" />
        </div>
        <div class="col pb-4">
          <ModalImage small={geo2} large={geo2} alt="Pool" />
        </div>
        <div class="col pb-4">
          <ModalImage small={geo4} large={geo4} alt="Pool" />
        </div>
        <div class="col pb-4">
          <ModalImage small={geo7} large={geo7} alt="Pool" />
        </div>
      </div>
      <BackButton />
    </div>
  );
}

export default Geometric;
