import React from "react";
import BackButton from "../Components/BackButton";
import ModalImage from "react-modal-image";
import backyard1 from "../images/backyard1.jpg";
import backyard2 from "../images/backyard2.jpg";
import backyard3 from "../images/backyard3.jpg";
import backyard4 from "../images/backyard4.jpg";
import backyard5 from "../images/backyard5.jpg";
import backyard6 from "../images/backyard6.jpg";
import backyard7 from "../images/backyard7.jpg";
import backyard8 from "../images/backyard8.jpg";

function Backyard() {
  return (
    <div className="container-fluid text-center" style={{ paddingTop: "1%" }}>
      <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-4 pb-4">
        <div className="col pb-4">
          <ModalImage small={backyard7} large={backyard7} alt="Pool" />
        </div>
        <div className="col pb-4">
          <ModalImage small={backyard5} large={backyard5} alt="Pool" />
        </div>
        <div className="col pb-4">
          <ModalImage small={backyard6} large={backyard6} alt="Pool" />
        </div>
        <div className="col pb-4">
          <ModalImage small={backyard1} large={backyard1} alt="Pool" />
        </div>
      </div>
      <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-4 pb-4">
        <div className="col pb-4">
          <ModalImage small={backyard2} large={backyard2} alt="Pool" />
        </div>
        <div className="col pb-4">
          <ModalImage small={backyard3} large={backyard3} alt="Pool" />
        </div>
        <div className="col pb-4">
          <ModalImage small={backyard4} large={backyard4} alt="Pool" />
        </div>
        <div className="col pb-4">
          <ModalImage small={backyard8} large={backyard8} alt="Pool" />
        </div>
      </div>
      <BackButton />
    </div>
  );
}

export default Backyard;
