import React from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import ModalImage from "react-modal-image";
import BackButton from "../Components/BackButton";
import freeform1 from "../images/freeform1.jpg";
import freeform2 from "../images/freeform2.jpg";
import freeform3 from "../images/freeform3.jpg";
import freeform4 from "../images/freeform4.jpg";
import freeform5 from "../images/freeform5.jpg";
import freeform6 from "../images/freeform6.jpg";
import freeform7 from "../images/freeform7.jpg";
import freeform8 from "../images/freeform8.jpg";

function FreeForm() {
  return (
    <div className="container-fluid text-center" style={{ paddingTop: "1%" }}>
      <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-4 pb-4">
        <div className="col pb-4">
          <ModalImage
            small={freeform1}
            large={freeform1}
            hideDownload={true}
            hideZoom={true}
            alt="Pool"
            className="modal-image"
          />
        </div>
        <div className="col pb-4">
          <ModalImage
            small={freeform2}
            large={freeform2}
            alt="Pool"
            className="modal-image"
          />
        </div>
        <div className="col pb-4">
          <ModalImage small={freeform3} large={freeform3} alt="Pool" />
        </div>
        <div className="col pb-4">
          <ModalImage small={freeform7} large={freeform7} alt="Pool" />
        </div>
      </div>
      <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-4">
        <div className="col pb-4">
          <ModalImage small={freeform5} large={freeform5} alt="Pool" />
        </div>
        <div className="col pb-4">
          <ModalImage small={freeform8} large={freeform8} alt="Pool" />
        </div>
        <div className="col pb-4">
          <ModalImage small={freeform4} large={freeform4} alt="Pool" />
        </div>
        <div className="col pb-4">
          <ModalImage
            small={freeform6}
            large={freeform6}
            alt="Pool"
            className="modal-image"
          />
        </div>
      </div>
      <BackButton />
    </div>
  );
}

export default FreeForm;
