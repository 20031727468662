import React from "react";
import SidebySide from "../Components/SidebySide";

function GetStarted() {
  return (
    <div>
      <SidebySide />
    </div>
  );
}

export default GetStarted;
