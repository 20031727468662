import React from "react";

function WhatWeDo() {
  return (
    <div className="py-5 bg-light">
      <div className="container px-4 my-0">
        <div className="row gx-5 justify-content-center">
          <div className="col-lg-10 col-xl-7">
            <div className="text-center">
              <h1>What We Do</h1>
              <div className="fs-4 mb-4 fst-italic">
                After completion and client approval of your pool plans and your
                sub-contractors have been solidified, the It's Your Pool team
                will provide you with information that will guide you through
                the pool permit process to your local city or county. We have
                qualified engineers who can also prepare structural plans that
                you may need for your project.
              </div>
              <div className="fs-4 mb-4 fst-italic">
                Our process fast tracks the permit process allowing you to start
                building your pool sooner than later to enjoy with your family
                and friends and the neighborhood if you wish.
              </div>
              <div className="fs-4 mb-0 fst-italic">
                After all, It's YOUR pool!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatWeDo;
