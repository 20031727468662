import React from "react";
import ContactUs from "../Components/ContactUs";

function Contact() {
  return (
    <div>
      <ContactUs />
    </div>
  );
}

export default Contact;
