import React from "react";
import { Link } from "react-router-dom";
import { Card, ListGroupItem, ListGroup, Button } from "react-bootstrap";
//import logo from "../poolhouse.jpg";
//import logo from "../Images/LOGO_5.png";
import logo from "../images/water3.jpg";

function CardComponent() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  return (
    <Card className="FullCard">
      <Card.Img variant="top" src={logo} />
      <Card.Body>
        <Card.Title>All Inclusive Package</Card.Title>
        <Card.Title>$3995.00</Card.Title>
        {/*<Button>Purchase Package</Button>*/}
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroupItem>
          Most Jobs Completed Under Commercial Builder Pricing
        </ListGroupItem>
        <ListGroupItem>Requires 1/3 of Builder Labor</ListGroupItem>
        <ListGroupItem>May Be Set At Any Elevation</ListGroupItem>
        <ListGroupItem>Construction Plan</ListGroupItem>
        <ListGroupItem>Property Site Plan</ListGroupItem>
        <ListGroupItem>Equipment Quote / Spec Sheet</ListGroupItem>
        <ListGroupItem>In-Floor Cleaner Design</ListGroupItem>
        <ListGroupItem>Email / Phone / InPerson Support</ListGroupItem>
        <ListGroupItem>Pool Studio 3D Composite Drawing</ListGroupItem>
        <ListGroupItem>2-D Custom Pool Design</ListGroupItem>
        <ListGroupItem>Includes 2 Revisions</ListGroupItem>
        <ListGroupItem>Pool Detail / Bid Sheet</ListGroupItem>
        <ListGroupItem>Construction Checklist</ListGroupItem>
        <ListGroupItem>Application / Submittal Check Sheet</ListGroupItem>
        <ListGroupItem>Sub-Contractor List</ListGroupItem>
        <ListGroupItem>Planning Designs</ListGroupItem>
      </ListGroup>
      <Card.Body>
        <Link to="/Billing">
          <Button variant="primary" size="lg" onClick={scrollToTop}>
            Purchase
          </Button>
        </Link>
        {/*<Card.Link href="/Contact">Ask Questions</Card.Link>
        <Card.Link href="/Purchase">Purchase Now</Card.Link>*/}
      </Card.Body>
    </Card>
  );
}

export default CardComponent;
