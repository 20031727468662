import React from "react";
import BackButton from "../Components/BackButton";
import ModalImage from "react-modal-image";
import water1 from "../images/water1.jpg";
import water2 from "../images/water2.jpg";
import water3 from "../images/water3.jpg";
import water4 from "../images/water4.jpg";
import water5 from "../images/water5.jpg";
import water6 from "../images/water6.jpg";
import water7 from "../images/water7.jpg";
import water8 from "../images/water8.jpg";

function WaterFeatures() {
  return (
    <div className="container-fluid text-center" style={{ paddingTop: "1%" }}>
      <div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-4 pb-4">
        <div class="col pb-4">
          <ModalImage small={water1} large={water1} alt="Pool" />
        </div>
        <div class="col pb-4">
          <ModalImage small={water2} large={water2} alt="Pool" />
        </div>
        <div class="col pb-4">
          <ModalImage small={water3} large={water3} alt="Pool" />
        </div>
        <div class="col pb-4">
          <ModalImage small={water5} large={water5} alt="Pool" />
        </div>
      </div>
      <div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-4 pb-4">
        <div class="col pb-4">
          <ModalImage small={water8} large={water8} alt="Pool" />
        </div>
        <div class="col pb-4">
          <ModalImage small={water6} large={water6} alt="Pool" />
        </div>
        <div class="col pb-4">
          <ModalImage small={water4} large={water4} alt="Pool" />
        </div>
        <div class="col pb-4">
          <ModalImage small={water7} large={water7} alt="Pool" />
        </div>
      </div>
      <BackButton />
    </div>
  );
}

export default WaterFeatures;
