import React from "react";
import HomeHeader from "../Components/HomeHeader";
import WhoWeAre from "../Components/WhoWeAre";
import WhatWeDo from "../Components/WhatWeDo";
import Package from "../Components/Package";
import QuestionComponent from "../Components/QuestionComponent";
import BottomNav from "../Components/BottomNav";

function Home() {
  return (
    <body className="d-flex flex-column h-100">
      <main className="flex-shrink-0">
        <HomeHeader />
        <WhoWeAre />
        <WhatWeDo />
        <Package />
        <QuestionComponent />
        <BottomNav />
      </main>
    </body>
  );
}

export default Home;
